'use client'

import { SwipeableDrawer, Toolbar } from '@mui/material'

// Types
import type { DrawerProps } from '@/types/components/molecules/drawer'

const SwipeableDrawerMolecule = (props: DrawerProps) => {
	// Props
	const { open, setOpen, children } = props

	return (
		<SwipeableDrawer
			variant="temporary"
			anchor="left"
			open={open}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			transitionDuration={0}
			sx={{
				display: { md: 'none' },
				'& .MuiDrawer-paper': {
					width: 1,
					px: 1
				}
			}}
		>
			<Toolbar />
			{children}
		</SwipeableDrawer>
	)
}

export default SwipeableDrawerMolecule
